<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-toolbar-title
        class="text-sm-h5 text-subtitle-1"
        style="cursor: pointer"
        v-bind="attrs"
        v-on="on"
        @click="redirectToIndex"
        >{{ title }}</v-toolbar-title
      >
    </template>
    <span>{{ titleSpan }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    title: { type: String },
    titleSpan: { type: String },
  },
};
</script>