<template>
  <v-col
    cols="12"
    md="6"
    sm="10"
    offset="0"
    offset-md="1"
    offset-sm="1"
  >
    <v-card :color="cardColor" elevation="2">
      <!-- Card Title -->
      <v-card-title class="headline justify-center">{{
        cardTitle
      }}</v-card-title>

      <v-divider />

      <!-- Card Text -->
      <v-card-text>
        <v-row>
          <v-col align="center" class="mx-0">
            <v-icon x-large :color="iconColor">{{ iconName }}</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="left" class="mx-0 text-justify">{{ cardText }}</v-col>
        </v-row>
      </v-card-text>

      <!-- Card Actions -->
      <v-card-actions>
        <v-btn text :color="color" @click="reveal = true">
          {{ $t("info.more_info") }}
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <v-card
          :color="cardColor"
          elevation="2"
          v-if="reveal"
          class="transition-fast-in-fast-out v-card--reveal"
        >
          <v-card-title class="headline justify-center">{{
            extensionCardTitle
          }}</v-card-title>

          <v-divider />

          <v-card-text class="pb-0">
            <v-row>
              <v-col align="center" class="mx-0">
                <v-icon x-large :color="iconColor">{{ iconName }}</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" class="mx-0 text-justify">{{
                extensionCardText
              }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn text :color="color" @click="reveal = false">{{
              $t("info.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-card>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      reveal: false,
      color: this.$vuetify.theme.themes["light"].limeCardColor,
    };
  },
  props: {
    cardColor: { type: String },
    cardTitle: { type: String },
    iconColor: { type: String },
    iconName: { type: String },
    cardText: { type: String },
    extensionCardTitle: { type: String },
    extensionCardText: { type: String },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
