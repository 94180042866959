import it from '@/locales/it.json';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import fr from '@/locales/fr.json';
import es from '@/locales/es.json';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'it',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
  messages: {
    it: it,
    en: en,
    de: de,
    fr: fr,
    es: es
  }
});