<template>
  <v-img :src="backgroundImageUrl" :aspect-ratio="aspectRatio" dark>
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey lighten-5" />
      </v-row>
    </template>

    <v-row align-md="start" align="start">
      <v-col
        v-for="lang in firstRowLanguages"
        :key="lang.language"
        align="center"
        cols="3"
        md="4"
        sm="3"
        offset-md="0"
        offset-sm="1"
        offset="1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div data-aos="zoom-in-down" data-aos-duration="1500">
              <v-img
                :max-height="imgSize"
                :max-width="imgSize"
                :src="flagIconUrl(lang.icon)"
                @click="redirectToLocale(lang.language)"
                style="cursor: pointer"
              />

              <p
                class="white--text"
                @click="redirectToLocale(lang.language)"
                v-bind="attrs"
                v-on="on"
              >
                {{ lang.language }}
              </p>
            </div>
          </template>
          <span>{{ lang.language }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- Extra space --->
    <v-row v-for="index in space" :key="index" :class="padding">
      <v-col />
    </v-row>

    <v-row class="pt-16" align-md="end" align="end">
      <v-col
        v-for="lang in secondRowLanguages"
        :key="lang.language"
        align="center"
        cols="3"
        md="3"
        sm="2"
        offset="2"
        offset-sm="3"
        offset-md="2"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div data-aos="zoom-in-up" data-aos-duration="1500">
              <v-img
                :max-height="imgSize"
                :max-width="imgSize"
                :src="flagIconUrl(lang.icon)"
                @click="redirectToLocale(lang.language)"
                style="cursor: pointer"
              />

              <p
                class="white--text"
                @click="redirectToLocale(lang.language)"
                v-bind="attrs"
                v-on="on"
              >
                {{ lang.language }}
              </p>
            </div>
          </template>
          <span>{{ lang.language }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
import { getUrl } from "../utils/firebase-utils";
import { auth } from "../config/firebase";
import { getValueFromStorage } from "../utils/helper-utils";
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";
import italyFlag from "../assets/icons/italy-flag.svg";
import franceFlag from "../assets/icons/france-flag.svg";
import englandFlag from "../assets/icons/england-flag.svg";
import spainFlag from "../assets/icons/spain-flag.svg";
import germanFlag from "../assets/icons/german-flag.svg";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      iconMap: new Map(),
      backgroundImageUrl: "",
      firstRowLanguages: [
        {
          language: "Italiano",
          icon: "it",
        },
        {
          language: "English",
          icon: "gb",
        },
        {
          language: "Français",
          icon: "fr",
        },
      ],
      secondRowLanguages: [
        {
          language: "Deutsch",
          icon: "de",
        },
        {
          language: "Español",
          icon: "es",
        },
      ],
    };
  },
  async created() {
    try {
      await signInAnonymously(auth);
    } catch (error) {
      console.error(error.message);
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        sessionStorage.setItem("uid", uid);
      } else {
        if (sessionStorage.getItem("uid") !== null) {
          sessionStorage.removeItem("uid");
        }
      }
    });

    this.backgroundImageUrl = await this.getBackgroundUrl();

    this.iconMap.set("it", italyFlag);
    this.iconMap.set("fr", franceFlag);
    this.iconMap.set("es", spainFlag);
    this.iconMap.set("de", germanFlag);
    this.iconMap.set("gb", englandFlag);
  },
  computed: {
    flagIconUrl() {
      return (icon) => {
        return this.iconMap.get(icon);
      };
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return "pb-16";
        case "md":
          return "pb-16";
        case "sm":
          return "pb-16";
        case "xs":
          return "pb-14";
        default:
          return "";
      }
    },
    imgSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 64;
        case "md":
          return 48;
        case "sm":
          return 48;
        case "xs":
          return 40;
        default:
          return 0;
      }
    },
    space() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 5;
        case "md":
          return 8;
        case "sm":
          return 3;
        case "xs":
          return 3;
        default:
          return 2;
      }
    },
    aspectRatio() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 2.3;
        case "sm":
          return 2.7;
        case "xs":
          return 16 / 9;
        default:
          return 2.3;
      }
    },
  },
  methods: {
    redirectToLocale(language) {
      language = language.toLowerCase();

      switch (language) {
        case "italiano":
          this.$i18n.locale = "it";
          break;
        case "english":
          this.$i18n.locale = "en";
          break;
        case "deutsch":
          this.$i18n.locale = "de";
          break;
        case "français":
          this.$i18n.locale = "fr";
          break;
        case "español":
          this.$i18n.locale = "es";
          break;
      }
      this.$router.push({ path: `${this.$i18n.locale}/home` });
    },
    async getBackgroundUrl() {
      const url = await getUrl("image/extra/background_starter_page_2.jpg");
      return getValueFromStorage("background_starter_page_2", url);
    },
  },
};
</script>