<template>
  <v-container>
    <!-- Setting title page -->
    <PageTitle v-bind:title="this.setTitle()" />
    <v-row>
      <DarkParagraphMid
        v-bind:cardColor="this.$vuetify.theme.themes.dark.limeCardColor"
        v-bind:cardTitle="this.setCardTitle()"
        :iconColor="this.$vuetify.theme.themes.dark.carbonColor"
        iconName="fas fa-plane-departure"
        v-bind:cardText="this.setCardText()"
      />
      <!-- Leaflet Map -->
      <v-col cols="10" md="5" sm="10" offset="1" offset-sm="1" offset-md="0">
        <v-sheet :color="sheet" elevation="4" rounded>
          <MapVisual />
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-carousel cycle>
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="`${item}`"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/Utils/PageTitle.vue";
import MapVisual from "@/components/Whereweare/MapVisual.vue";
import DarkParagraphMid from "@/components/Utils/DarkParagraphMid.vue";
import { getUrls } from "../utils/firebase-utils";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      items: []
    };
  },
  methods: {
    setTitle() {
      return this.$t("where_we_are.point");
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    setCardTitle() {
      return this.$t("where_we_are.first_paragraph_title");
    },
    setCardText() {
      return this.$t("where_we_are.first_paragraph");
    },
  },
  async mounted() {
    this.items = await getUrls();
  },
  components: {
    MapVisual,
    PageTitle,
    DarkParagraphMid,
  },
  computed: {
    sheet() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].sheet;
    },
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  z-index: 1;
}
</style>
