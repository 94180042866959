<template>
  <v-row>
    <v-col cols="12" md="10" sm="12" offset-md="1" offset="0">
      <div data-aos="zoom-in-down" data-aos-duration="1500">
        <vue-player :src="src" :poster="poster" :title="title" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import vuePlayer from "@algoz098/vue-player";

export default {
  props: {
    src: { type: String },
    poster: { type: String },
    title: { type: String },
  },
  components: {
    vuePlayer,
  },
};
</script>
