<template>
  <v-row>
    <v-col cols="12" md="8" sm="10" offset-md="2" offset-sm="1">
      <v-hover v-slot="{ hover }" open-delay="100">
        <div data-aos="zoom-in" data-aos-duration="1500">
          <v-card
            :elevation="hover ? 16 : 4"
            :class="{ 'on-hover mx-auto': hover }"
            :color="cardColor"
          >
            <!-- CardTitle -->
            <v-card-title class="headline justify-center">{{
              cardTitle
            }}</v-card-title>

            <v-divider />

            <!-- CardContent -->
            <v-card-text>
              <v-row>
                <v-col align="center" class="mx-0">
                  <div data-aos="zoom-in-down" data-aos-duration="3000">
                    <v-icon x-large :color="iconColor">{{ iconName }}</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left" class="mx-0 text-justify">{{
                  cardText
                }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    cardColor: { type: String },
    cardTitle: { type: String },
    iconColor: { type: String },
    iconName: { type: String },
    cardText: { type: String },
  },
};
</script>
