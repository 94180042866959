<template>
  <v-row>
    <v-col cols="10" md="8" sm="10" offset="1" offset-md="2" offset-sm="1">
      <v-card :color="cardColor" elevation="2">
        <!-- CardTitle -->
        <v-card-title class="headline justify-center black--text">{{ this.$t("info.price_paragraph_title") }}</v-card-title>

        <!-- CardContent -->
        <v-card-text>
          <v-row>
            <v-card-subtitle class="black--text">{{ this.$t("info.price_paragraph_subtitle") }}</v-card-subtitle>
          </v-row>
          <v-row>
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="black--text">{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="black--text">{{ this.$t("info.price_paragraph_point_one") }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="black--text">{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="black--text">{{ this.$t("info.price_paragraph_point_two") }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="black--text">{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="black--text">{{ this.$t("info.price_paragraph_point_three") }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="black--text">{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="black--text">{{ this.$t("info.price_paragraph_point_four") }}</v-list-item-subtitle>
            </v-list-item>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      icon: "fas fa-check-square",
    };
  },
  props: {
    cardColor: { type: String },
    iconColor: { type: String },
  },
};
</script>
