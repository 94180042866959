<template>
  <v-row>
    <v-col cols="10" offset="1" md="8" offset-md="2" sm="10" offset-sm="1" xs="1" offset-xs="6">
      <v-card :color="cardColor">
        <!-- CardTitle -->
        <v-card-title class="headline justify-center">{{ cardTitle }}</v-card-title>

        <!-- CardContent -->
        <v-card-text>
          <v-row>
            <v-col align="center" class="mx-0">
              <v-icon x-large :color="this.$vuetify.theme.themes['common'].carbonColor">{{ iconName }}</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left" class="mx-0 text-justify text-body-3">{{ cardText }}</v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mx-4" />
        <v-card-actions>
          <v-btn text tag="router-link" to="book-flight">{{ cardButton }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    cardColor: { type: String },
    cardTitle: { type: String },
    iconName: { type: String },
    cardText: { type: String },
    cardButton: { type: String },
  },
};
</script>
