<template>
  <v-col
    cols="12"
    md="4"
    sm="12"
    xs="6"
    offset-xs="2"
    offset-md="0"
    offset-sm="0"
  >
    <v-hover v-slot="{ hover }" open-delay="100">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover mx-auto': hover }"
        :color="cardColor"
        dark
        max-width="400"
      >
        <v-card-title class="headline justify-center black--text">{{
          pilotProfile
        }}</v-card-title>

        <v-divider />

        <v-card-text>
          <v-row class="mx-0">
            <div class="my-4 black--text font-weight-bold">
              {{ firstParagraphName }}
            </div>
            <div class="my-4 black--text">Lorenzo De Grandis</div>
          </v-row>

          <v-row class="mx-0">
            <div class="my-4 black--text font-weight-bold">
              {{ firstParagraphPhoneNumber }}
            </div>
            <div class="my-4 black--text">339 2309896</div>
          </v-row>

          <v-row class="mx-0">
            <div class="my-4 black--text">
              {{ firstParagraphDescription }}
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      cardColor: this.$vuetify.theme.themes.dark.limeCardColor,
      pilotProfile: this.$t("contacts.pilot_profile"),
      firstParagraphName: this.$t("contacts.first_paragraph_name"),
      firstParagraphPhoneNumber: this.$t(
        "contacts.first_paragraph_phone_number"
      ),
      firstParagraphDescription: this.$t(
        "contacts.first_paragraph_description"
      ),
    };
  },
};
</script>
