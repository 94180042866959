<template>
  <v-container>
    <!-- Setting title page -->
    <PageTitle v-bind:title="this.setTitle()" />

    <v-row>
      <!-- First Image -->
      <ImageParagraph v-bind:cardColor="this.$vuetify.theme.themes.dark.limeCardColor" :src="expertPilotUrl" height="215" />
      <!-- Pilot Card -->
      <PilotCard />
      <!-- Second Image -->
      <ImageParagraph v-bind:cardColor="this.$vuetify.theme.themes.dark.limeCardColor" :src="contactsFlightUrl" height="215" />
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/Utils/PageTitle.vue";
import ImageParagraph from "@/components/Contacts/ImageParagraph.vue";
import PilotCard from "@/components/Contacts/PilotCard.vue";
import { getUrl } from "../utils/firebase-utils";
import { getValueFromStorage } from "../utils/helper-utils";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      expertPilotUrl: "",
      contactsFlightUrl: "",
    };
  },
  async mounted() {
    this.expertPilotUrl = await this.getExpertPilotUrl();
    this.contactsFlightUrl = await this.getContactsFlightUrl();
  },
  methods: {
    setTitle() {
      return this.$t("contacts.title");
    },
    async getExpertPilotUrl() {
      const url = await getUrl("image/contacts/contacts_expert_pilot.jpg");
      return getValueFromStorage("contacts_expert_pilot", url);
    },
    async getContactsFlightUrl() {
      const url = await getUrl("image/contacts/contacts_flight.jpg");
      return getValueFromStorage("contacts_flight", url);
    },
  },
  components: {
    PageTitle,
    ImageParagraph,
    PilotCard,
  },
};
</script>
