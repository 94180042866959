<template>
  <v-list-item tag="router-link" :to="routerPath">
    <v-list-item-icon>
      <v-icon>{{ iconName }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ itemTitle }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    routerPath: { type: String },
    iconName: { type: String },
    itemTitle: { type: String },
  },
};
</script>
