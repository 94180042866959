var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto overflow-hidden",attrs:{"height":"400"}},[_c('v-app-bar',{attrs:{"app":"","fixed":"","color":this.$vuetify.theme.themes.dark.toolbarColor,"dark":"","shrink-on-scroll":"","fade-img-on-scroll":"","scroll-threshold":"200","elevate-on-scroll":"","scroll-off-screen":"","height":"300","src":_vm.backgroundToolbarUrl},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"}},'v-img',props,false))]}}])},[_c('Logo'),_c('v-app-bar-nav-icon',{staticClass:"hidden-sm-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('ToolbarTitle',{attrs:{"title":this.title,"titleSpan":_vm.setTitleSpan()}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"text":"","tag":"router-link","to":"home"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("toolbar.home"))+" ")])]}}])},[_c('span',[_vm._v("Home")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"text":"","tag":"router-link","to":"info"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("toolbar.info"))+" ")])]}}])},[_c('span',[_vm._v("Info")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"text":"","tag":"router-link","to":"where-we-are"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("toolbar.where_we_are"))+" ")])]}}])},[_c('span',[_vm._v("Where we are")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"text":"","tag":"router-link","to":"contacts"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("toolbar.contacts"))+" ")])]}}])},[_c('span',[_vm._v("Contacts")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"text":"","tag":"router-link","to":"gallery"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("toolbar.gallery"))+" ")])]}}])},[_c('span',[_vm._v("Gallery")])]),_c('v-spacer'),_c('LanguageMenu'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.changeTheme}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.themeSwitcher))])],1)]}}])},[_c('span',[_vm._v("Change theme")])])],1),_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":"","color":_vm.cardColor}},[_c('v-list-item-group',{attrs:{"active-class":"lime--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('NavigationDrawerItem',{attrs:{"routerPath":"home","iconName":"fas fa-home","itemTitle":this.setItemTitle('home')}}),_c('NavigationDrawerItem',{attrs:{"routerPath":"info","iconName":"fas fa-info-circle","itemTitle":this.setItemTitle('info')}}),_c('NavigationDrawerItem',{attrs:{"routerPath":"where-we-are","iconName":"fas fa-map-marker-alt","itemTitle":this.setItemTitle('where-we-are')}}),_c('NavigationDrawerItem',{attrs:{"routerPath":"contacts","iconName":"fas fa-id-card","itemTitle":this.setItemTitle('contacts')}}),_c('NavigationDrawerItem',{attrs:{"routerPath":"gallery","iconName":"fas fa-camera","itemTitle":this.setItemTitle('gallery')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }