<template>
  <v-col class="pb-12" cols="10" md="4" sm="8" xs="6" offset="0" offset-md="0" offset-sm="2">
    <v-card :color="cardColor">
      <v-img :src="src" :height="height" :width="width" class="grey darken-4">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-row>
        </template>
      </v-img>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    cardColor: { type: String },
    src: { type: String },
    height: { type: String },
    width: { type: String },
  },
};
</script>
