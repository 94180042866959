<template>
  <v-container class="pt-0 pb-0 pl-0 pr-0">
    <!-- Setting title page -->
    <PageTitle v-bind:title="this.setTitle()" />

    <v-row>
      <ExtensibleParagraph
        v-bind:cardColor="cardColor"
        v-bind:cardTitle="this.setCardTitle('first-paragraph')"
        :iconColor="iconColor"
        iconName="fas fa-hard-hat"
        v-bind:cardText="this.setCardText('first-paragraph')"
        v-bind:extensionCardTitle="this.setCardTitle('third-paragraph')"
        v-bind:extensionCardText="this.setCardText('third-paragraph')"
      />

      <LargerImageParagraph
        :cardColor="cardColor"
        :src="imgGirl"
        :height="imgGirlHeight"
      />
    </v-row>
    <v-parallax :src="parallaxUrl" :height="parallaxHeight">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="text-h2 font-weight-thin mb-2">
            {{ $t("info.parallax") }}
          </h1>
        </v-col>
      </v-row>
    </v-parallax>

    <v-row>
      <v-col>
        <h2 class="text-center text-decoration-underline text-uppercase">
          {{ $t("info.subtitle") }}
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <AggregateParagraph
        v-bind:cardTitle="this.setCardTitle('')"
        iconName="fas fa-plane-departure"
        v-bind:cardText="this.setAggregateCardText()"
        v-bind:cardButton="this.setButtonText()"
      />
    </v-row>

    <v-row>
      <LargerParagraph
        v-bind:cardColor="this.$vuetify.theme.themes.dark.limeCardColor"
        v-bind:iconColor="iconColor"
      />
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/Utils/PageTitle.vue";
import LargerImageParagraph from "@/components/Utils/LargerImageParagraph.vue";
import LargerParagraph from "@/components/Info/LargerParagraph.vue";
import ExtensibleParagraph from "@/components/Utils/ExtensibleParagraph.vue";
import AggregateParagraph from "@/components/Info/AggregateParagraph.vue";
import { getUrl } from "../utils/firebase-utils";
import { getValueFromStorage } from "../utils/helper-utils";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "This is the about page of the site." },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      pinkCardColor: "#AEEA00",
      videoUrl: "",
      imgGirl: "",
      parallaxUrl: "",
    };
  },
  methods: {
    setTitle() {
      return this.$t("info.title");
    },
    setVideoTitle() {
      return this.$t("home.video_title");
    },
    setCardTitle(paragraph) {
      switch (paragraph) {
        case "first-paragraph":
          return this.$t("info.first_paragraph_title");
        case "second-paragraph":
          return this.$t("info.second_paragraph_title");
        case "third-paragraph":
          return this.$t("info.third_paragraph_title");
        case "fourth-paragraph":
          return this.$t("info.fourth_paragraph_title");
        case "fifth-paragraph":
          return this.$t("info.fifth_paragraph_title");
      }
    },
    setCardText(paragraph) {
      switch (paragraph) {
        case "first-paragraph":
          return this.$t("info.first_paragraph");
        case "second-paragraph":
          return this.$t("info.second_paragraph");
        case "third-paragraph":
          return this.$t("info.third_paragraph");
        case "fifth-paragraph":
          return this.$t("info.fifth_paragraph");
      }
    },
    setAggregateCardText() {
      const pointOneFirst = this.$t("info.point_one_first");
      const pointOneLink = this.$t("info.point_one_link");
      const pointOne = pointOneFirst.concat(pointOneLink);
      const pointTwo = this.$t("info.point_two");
      const pointThree = this.$t("info.point_three");

      return pointOne
        .concat(" ")
        .concat(pointTwo)
        .concat(" ")
        .concat(pointThree);
    },
    setButtonText() {
      return this.$t("book_flight.button_reserve");
    },
    async setImageGirlUrl() {
      const url = await getUrl("image/info/info_girl.jpeg");
      return getValueFromStorage("info_girl", url);
    },
    async setInfoParallaxUrl() {
      const url = await getUrl("image/info/info_parallax.jpg");
      return getValueFromStorage("info_parallax", url);
    },
  },
  async mounted() {
    this.imgGirl = await this.setImageGirlUrl();
    this.parallaxUrl = await this.setInfoParallaxUrl();
  },
  computed: {
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
    iconColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].iconColor;
    },
    parallaxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          return 300;
        case "md":
          return 400;
        case "lg":
          return 400;
        case "xl":
          return 400;
        default:
          return 400;
      }
    },
    imgGirlHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "300";
        case "md":
          return "300";
        case "lg":
          return "400";
        case "xl":
          return "400";
        default:
          return "400";
      }
    },
  },
  components: {
    PageTitle,
    LargerImageParagraph,
    ExtensibleParagraph,
    AggregateParagraph,
    LargerParagraph,
  },
};
</script>
