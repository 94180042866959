function extractLocaleFromPath(path) {
    path = path.split('/');
    return path[1];
}

const getValueFromStorage = async (key, value) => {
    if (localStorage.getItem(key) !== null) {
        return localStorage.getItem(key);
    }
    localStorage.setItem(key, value);
    return value;
}

function buildErrorMessage(locale) {
    switch (locale) {
        case 'en':
            return 'We are sorry, but the request was not successful.';
        case 'it':
            return 'Siamo spiacenti, ma la richiesta non è andata a buon fine.';
        case 'fr':
            return 'Nous sommes désolés, mais la demande n\'a pas abouti.';
        case 'es':
            return 'Lo sentimos, pero la solicitud no fue exitosa.';
        case 'de':
            return 'Es tut uns leid, aber die Anfrage war nicht erfolgreich.';
    }
}

export {
    extractLocaleFromPath,
    buildErrorMessage,
    getValueFromStorage
}