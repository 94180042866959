<template>
  <v-col cols="10" md="4" sm="10" xs="6" offset="1" offset-md="0" offset-sm="1">
    <v-card elevation="4" :color="cardColor">
      <v-img :src="src" :height="height" class="grey darken-4" />
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    cardColor: { type: String },
    src: { type: String },
    height: { type: String },
  },
};
</script>
