import Vue from 'vue';
import VuePageTitle from 'vue-page-title';
import VueLogger from 'vuejs-logger';
import App from './App.vue';
import store from '../src/store/index';
import router from './router/index';
import vuetify from './plugins/vuetify';
import { i18n } from './i18n';
import { Icon } from "leaflet";
import VueMeta from 'vue-meta';
import AOS from 'aos';
import 'leaflet/dist/leaflet.css';
import 'aos/dist/aos.css';

delete Icon.Default.prototype._getIconUrl;

const isProduction = process.env.NODE_ENV === 'production';

const options = {
   isEnabled: true,
   logLevel: isProduction ? 'error' : 'debug',
   stringifyArguments: false,
   showLogLevel: true,
   showMethodName: true,
   separator: '|',
   showConsoleColors: true
};

Icon.Default.mergeOptions({
   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
   iconUrl: require("leaflet/dist/images/marker-icon.png"),
   shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.use(VueMeta);
Vue.use(VuePageTitle, { router });
Vue.use(VueLogger, options);
Vue.config.productionTip = false;

new Vue({
   created() {
      AOS.init()
   },
   store: store,
   router: router,
   i18n: i18n,
   vuetify: vuetify,
   render: h => h(App)
}).$mount('#app');
