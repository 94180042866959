import colors from 'vuetify/lib/util/colors';

const constants = {
    app_title: 'HangGlidingLaveno',
    where_we_are_location: 'Laveno Mombello, Via Cittiglio 1',
    iconSun: 'far fa-sun',
    iconMoon: 'far fa-moon',
    limeColor: colors.lime.base,
    carbonColor: '#232323',
};

export default constants;