<template>
  <v-container>
    <LMap :zoom="zoom" :center="center" style="height: 200px;">
      <LTileLayer :url="url"></LTileLayer>
      <LMarker :lat-lng="latLng">
        <LPopup>
          <div @click="openGoogleMaps">
            Click here to display google maps
          </div>
        </LPopup>
      </LMarker>
      <LMarker :lat-lng="[45.896794, 8.638401]">
        <LIcon :icon-size="[32, 37]" :icon-anchor="[16, 37]" :icon-url="require('../../assets/images/icon-bar.png')" />
      </LMarker>
    </LMap>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";

export default {
  name: "MapVisual",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 16,
      center: [45.89545, 8.63848],
      latLng: [45.89545, 8.63848],
    };
  },
  methods: {
    openGoogleMaps() {
      window.open(`https://www.google.com/maps/search/?api=1&query=${this.latLng}`, "_blank");
    },
  },
};
</script>
