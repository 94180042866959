import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.VUE_APP_PROJECT_ID}.firebaseapp.com`,
    projectId: `${process.env.VUE_APP_PROJECT_ID}`,
    storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
    messagingSenderId: "604220626147",
    appId: "1:604220626147:web:23883b85fc91141c72a1d6",
    measurementId: "G-SSWYPBBH54"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const performance = getPerformance(app);

export { analytics, storage, performance, auth, logEvent };