<template>
  <v-card class="mx-auto overflow-hidden" height="400">
    <v-app-bar
      app
      fixed
      :color="this.$vuetify.theme.themes.dark.toolbarColor"
      dark
      shrink-on-scroll
      fade-img-on-scroll
      scroll-threshold="200"
      elevate-on-scroll
      scroll-off-screen
      height="300"
      :src="backgroundToolbarUrl"
    >
      <Logo />

      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        />
      </template>

      <v-app-bar-nav-icon class="hidden-sm-and-up" @click="drawer = !drawer" />

      <ToolbarTitle :title="this.title" :titleSpan="setTitleSpan()" />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            text
            tag="router-link"
            to="home"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("toolbar.home") }}
          </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            text
            tag="router-link"
            to="info"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("toolbar.info") }}
          </v-btn>
        </template>
        <span>Info</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            text
            tag="router-link"
            to="where-we-are"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("toolbar.where_we_are") }}
          </v-btn>
        </template>
        <span>Where we are</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            text
            tag="router-link"
            to="contacts"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("toolbar.contacts") }}
          </v-btn>
        </template>
        <span>Contacts</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            text
            tag="router-link"
            to="gallery"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("toolbar.gallery") }}
          </v-btn>
        </template>
        <span>Gallery</span>
      </v-tooltip>

      <v-spacer />

      <LanguageMenu />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon>
            <v-icon @click="changeTheme" v-bind="attrs" v-on="on">{{
              themeSwitcher
            }}</v-icon>
          </v-btn>
        </template>
        <span>Change theme</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-list nav dense :color="cardColor">
        <v-list-item-group
          v-model="group"
          active-class="lime--text text--accent-4"
        >
          <NavigationDrawerItem
            routerPath="home"
            iconName="fas fa-home"
            v-bind:itemTitle="this.setItemTitle('home')"
          />
          <NavigationDrawerItem
            routerPath="info"
            iconName="fas fa-info-circle"
            v-bind:itemTitle="this.setItemTitle('info')"
          />
          <NavigationDrawerItem
            routerPath="where-we-are"
            iconName="fas fa-map-marker-alt"
            v-bind:itemTitle="this.setItemTitle('where-we-are')"
          />
          <NavigationDrawerItem
            routerPath="contacts"
            iconName="fas fa-id-card"
            v-bind:itemTitle="this.setItemTitle('contacts')"
          />
          <NavigationDrawerItem
            routerPath="gallery"
            iconName="fas fa-camera"
            v-bind:itemTitle="this.setItemTitle('gallery')"
          />
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import constants from "../../utils/constants";
import { getUrl } from "../../utils/firebase-utils";
import { getValueFromStorage } from "../../utils/helper-utils";
import NavigationDrawerItem from "@/components/Utils/NavigationDrawerItem.vue";
import LanguageMenu from "./LanguageMenu.vue";
import Logo from "@/components/Utils/Logo.vue";
import ToolbarTitle from "@/components/Utils/ToolbarTitle.vue";

export default {
  data() {
    return {
      drawer: false,
      group: null,
      backgroundToolbarUrl: "",
      languages: [
        {
          id: 1,
          title: "Italiano",
        },
        {
          id: 2,
          title: "English",
        },
        {
          id: 3,
          title: "Deutsch",
        },
        {
          id: 4,
          title: "Français",
        },
        {
          id: 5,
          title: "Español",
        },
      ],
      title: constants.app_title,
      language: "",
    };
  },
  async created() {
    this.backgroundToolbarUrl = await this.getBackgroundToolbarUrl();
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    redirectToIndex() {
      this.$router.push({ path: "/" });
    },
    async getBackgroundToolbarUrl() {
      const url = await getUrl("image/extra/background_starter_page.jpg");
      return getValueFromStorage("background_starter_page", url);
    },
    setTitleSpan() {
      return this.$t("toolbar.title_span");
    },
    setItemTitle(type) {
      switch (type) {
        case "home":
          return this.$t("toolbar.home");
        case "info":
          return this.$t("toolbar.info");
        case "where-we-are":
          return this.$t("toolbar.where_we_are");
        case "contacts":
          return this.$t("toolbar.contacts");
        case "gallery":
          return this.$t("toolbar.gallery");
      }
    },
  },
  computed: {
    themeSwitcher() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      if (theme === "dark") {
        return constants.iconMoon;
      }
      return constants.iconSun;
    },
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  components: {
    NavigationDrawerItem,
    LanguageMenu,
    Logo,
    ToolbarTitle,
  },
};
</script>
