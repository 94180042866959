import Vue from 'vue';
import VueRouter from "vue-router";
import Home from '@/views/Home.vue';
import Info from '@/views/Info.vue';
import WhereWeAre from '@/views/Whereweare.vue';
import Contacts from '@/views/Contacts.vue';
import Index from '@/views/Index.vue';
import Gallery from '@/views/Gallery.vue';
import TermsOfService from '@/views/TermsOfService.vue';
import NotFound from '@/views/NotFound.vue';
import constants from '../utils/constants';
import { i18n } from '../i18n';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: 'Index',
        component: Index,
        meta: {
            title: constants.app_title
        }
    },
    {
        path: '/:locale',
        component: {
            render: h => h('router-view')
        },
        children: [
            {
                path: "home",
                name: 'Home',
                component: Home,
                meta: {
                    title: `Home | ${constants.app_title}`
                }
            },
            {
                path: "info",
                name: 'Info',
                component: Info,
                meta: {
                    title: `Info | ${constants.app_title}`
                }
            },
            {
                path: "where-we-are",
                name: 'WhereWeAre',
                component: WhereWeAre,
                meta: {
                    title: `Where we are | ${constants.app_title}`
                }
            },
            {
                path: "contacts",
                name: 'Contacts',
                component: Contacts,
                meta: {
                    title: `Contacts | ${constants.app_title}`
                }
            },
            {
                path: "gallery",
                name: "Gallery",
                component: Gallery,
                meta: {
                    title: `Gallery | ${constants.app_title}`
                }
            },
            {
                path: "terms-of-service",
                name: 'TermsOfService',
                component: TermsOfService,
                meta: {
                    title: `Terms Of Service | ${constants.app_title}`
                }
            },
            {
                path: "*",
                component: NotFound,
            }
        ]
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    i18n.locale = to.params.locale;
    next();
});

export default router;