<template>
  <v-container>
    <!-- Overlay -->
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <!-- Setting title page -->
    <PageTitle v-bind:title="this.setTitle()" />

    <v-row>
      <v-col
        v-for="(url, index) in this.images"
        :key="index"
        class="d-flex child-flex"
        cols="6"
        md="4"
      >
        <div data-aos="zoom-in-down" data-aos-duration="1000">
          <v-hover v-slot="{ hover }" open-delay="100">
            <v-card
              color="white"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover mx-auto': hover }"
            >
              <v-img
                :src="url"
                :lazy-src="url"
                aspect-ratio="1"
                class="grey lighten-2 rounded"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                  <v-sheet
                    :color="cardColor"
                    class="px-3 pt-3 pb-3 fill-height"
                  >
                    <v-skeleton-loader class="mx-auto" type="image" />
                  </v-sheet>
                </template>
              </v-img>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-pagination
            class="custom"
            v-model="page"
            :length="totalPages"
            circle
            prev-icon="fas fa-angle-left"
            next-icon="fas fa-angle-right"
            @input="handlePageChange"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/Utils/PageTitle.vue";
import { listAllFiles } from "../utils/firebase-utils";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      loader: true,
      images: [],
      totalPages: 2,
      length: 0,
      page: 1,
    };
  },
  computed: {
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
  },
  components: {
    PageTitle,
  },
  async created() {
    this.images = await listAllFiles(this.page);
    this.loader = false;
  },
  methods: {
    setTitle() {
      return this.$t("gallery.title");
    },
    handlePageChange() {
      listAllFiles(this.page)
        .then((urls) => {
          this.images = urls;
          this.$log.debug(this.images);
        })
        .catch((error) => {
          this.$log.error(error.message);
        });
    },
  },
};
</script>

<style scoped>
.theme--dark.v-pagination .v-pagination__item {
  background: #426478;
  color: #ffffff;
}
</style>