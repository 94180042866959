<template>
  <v-container>
    <!-- Setting title page -->
    <PageTitle v-bind:title="this.setTitle()" />

    <!-- VideoPlayer -->
    <VideoPlayer
      :src="videoUrl"
      :poster="posterUrl"
      v-bind:title="this.setVideoTitle()"
    />

    <LargerParagraph
      v-bind:cardColor="cardColor"
      v-bind:cardTitle="this.setCardTitle('first-paragraph')"
      v-bind:iconColor="iconColor"
      iconName="fas fa-sun"
      v-bind:cardText="this.setCardText('first-paragraph')"
    />
    <!-- Second Paragraph -->
    <v-row>
      <DarkParagraphMid
        v-bind:cardColor="this.limeColor"
        v-bind:cardTitle="this.setCardTitle('second-paragraph')"
        :iconColor="this.carbonColor"
        iconName="fas fa-hard-hat"
        v-bind:cardText="this.setCardText('second-paragraph')"
        fade="fade-right"
      />

      <ImageParagraph
        v-bind:cardColor="cardColor"
        v-bind:src="homePilotUrl"
        height="215"
        v-bind:alt="expertPilot"
        flip="flip-right"
      />
    </v-row>
    <v-row>
      <ImageParagraph
        v-bind:cardColor="cardColor"
        v-bind:src="flightUrl"
        height="215"
        v-bind:alt="homeFly"
        flip="flip-left"
      />

      <DarkParagraphMid
        v-bind:cardColor="this.limeColor"
        v-bind:cardTitle="this.setCardTitle('third-paragraph')"
        :iconColor="this.carbonColor"
        iconName="far fa-paper-plane"
        v-bind:cardText="this.setCardText('third-paragraph')"
        fade="fade-left"
      />
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/Utils/PageTitle.vue";
import VideoPlayer from "@/components/Utils/VideoPlayer.vue";
import LargerParagraph from "@/components/Home/LargerParagraph.vue";
import ImageParagraph from "@/components/Utils/ImageParagraph.vue";
import DarkParagraphMid from "@/components/Utils/DarkParagraphMid.vue";
import { getUrl } from "../utils/firebase-utils";
import { getValueFromStorage } from "../utils/helper-utils";
import constants from "../utils/constants";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "google-site-verification",
        content: "Wq4pmgI78TiBafvpAWe0ercUxCDP7MvdVU-ukNPjOkU",
      },
    ],
  },
  data() {
    return {
      videoUrl: "",
      homePilotUrl: "",
      flightUrl: "",
      posterUrl: "",
      expertPilot: "Home pilot",
      homeFly: "Fly",
      limeColor: constants.limeColor,
      carbonColor: constants.carbonColor
    };
  },
  computed: {
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
    iconColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].iconColor;
    },
  },
  async created() {
    this.posterUrl = await this.getPosterUrl();
    this.videoUrl = await this.getVideoUrl();
    this.homePilotUrl = await this.getHomePilotUrl();
    this.flightUrl = await this.getFlightUrl();
  },
  methods: {
    setTitle() {
      return this.$t("home.title");
    },
    setVideoTitle() {
      return this.$t("home.video_title");
    },
    setCardTitle(paragraph) {
      switch (paragraph) {
        case "first-paragraph":
          return this.$t("home.first_paragraph_title");
        case "second-paragraph":
          return this.$t("home.second_paragraph_title");
        case "third-paragraph":
          return this.$t("home.third_paragraph_title");
      }
    },
    setCardText(paragraph) {
      switch (paragraph) {
        case "first-paragraph":
          return this.$t("home.first_paragraph");
        case "second-paragraph":
          return this.$t("home.second_paragraph");
        case "third-paragraph":
          return this.$t("home.third_paragraph");
      }
    },
    async getPosterUrl() {
      const url = await getUrl("image/home/home_poster_video.png");
      return getValueFromStorage("home_poster_video", url);
    },
    async getVideoUrl() {
      const url = await getUrl("video/home_video.mp4");
      return getValueFromStorage("home_video", url);
    },
    async getHomePilotUrl() {
      const url = await getUrl("image/home/home_pilot.png");
      return getValueFromStorage("home_pilot", url);
    },
    async getFlightUrl() {
      const url = await getUrl("image/home/home_fly.png");
      return getValueFromStorage("home_fly", url);
    },
  },
  components: {
    PageTitle,
    VideoPlayer,
    LargerParagraph,
    ImageParagraph,
    DarkParagraphMid,
  },
};
</script>
