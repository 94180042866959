<template>
  <v-col cols="12" md="6" sm="10" offset="0" offset-md="0" offset-sm="1">
    <v-hover v-slot="{ hover }" open-delay="100">
      <div :data-aos="fade" data-aos-duration="1500">
        <v-card
          :color="cardColor"
          :elevation="hover ? 16 : 4"
          :class="{ 'on-hover mx-auto': hover }"
          v-show="expand"
          dark
        >
          <!-- CardTitle -->
          <v-card-title class="headline justify-center black--text">{{
            cardTitle
          }}</v-card-title>

          <v-divider />

          <!-- CardContent -->
          <v-card-text>
            <v-row>
              <v-col align="center" class="mx-0">
                <div data-aos="flip-down" data-aos-duration="1500">
                  <v-icon x-large :color="iconColor">{{ iconName }}</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" class="mx-0 text-justify black--text">{{
                cardText
              }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      expand: true,
    };
  },
  props: {
    cardColor: { type: String },
    cardTitle: { type: String },
    iconColor: { type: String },
    iconName: { type: String },
    cardText: { type: String },
    fade: { type: String },
  },
};
</script>

<style scoped>
.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}
</style>