<template>
  <v-footer dark padless>
    <v-card flat tile class="blue darken-2 white--text text-center flex">
      <v-card-text class="white--text"
        ><strong>{{ $t("footer.follow_us") }}</strong></v-card-text
      >

      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          dark
          icon
          :href="icon.link"
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider />
      <v-card-text class="white--text">
        &copy; {{ currentDate }}
        <strong>{{ title }} - Varese, Italy</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
import constants from "../../utils/constants";

export default {
  data() {
    return {
      currentDate: new Date().getFullYear(),
      title: constants.app_title,
      icons: [
        {
          text: "fab fa-facebook",
          link: "https://www.facebook.com/hglaveno",
        },
        {
          text: "fab fa-twitter",
          link: "",
        },
        {
          text: "fab fa-instagram",
          link: "",
        },
        {
          text: "fab fa-youtube",
          link: "",
        },
      ],
    };
  },
};
</script>
