<template>
  <v-container>
    <Subtitle :subtitle="setSubtitle()" />
    <Paragraph :text="setParagraphText(0)" />
    <Paragraph :text="setParagraphText(1)" />
    <Paragraph :text="setParagraphText(2)" />
    <Title :title="setTitle(0)" />
  </v-container>
</template>

<script>
import Paragraph from "../components/TermsOfService/Paragraph.vue";
import Subtitle from "../components/TermsOfService/Subtitle.vue";
import Title from "../components/TermsOfService/Title.vue";

export default {
  components: {
    Paragraph,
    Subtitle,
    Title,
  },
  methods: {
    setParagraphText(type) {
      switch (type) {
        case 0:
          return this.$t("terms_of_service.first_point_first");
        case 1:
          return this.$t("terms_of_service.first_point_second");
        case 2:
          return this.$t("terms_of_service.first_point_third");
      }
    },
    setSubtitle() {
      return this.$t("terms_of_service.first_point_title");
    },
    setTitle(type) {
      switch (type) {
        case 0:
          return this.$t("terms_of_service.second_point_title");
        case 1:
          return this.$t("terms_of_service.first_point_second");
        case 2:
          return this.$t("terms_of_service.first_point_third");
      }
    },
  },
};
</script>
