<template>
  <v-row>
    <v-col>
      <p>{{ subtitle }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    subtitle: { type: String },
  },
};
</script>
