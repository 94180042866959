<template>
  <v-menu open-on-hover top offset-y rounded transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>fas fa-globe-europe</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list dense :color="cardColor">
        <v-subheader class="justify-center text-subtitle-1">{{
          $t("toolbar.language_title")
        }}</v-subheader>
        <v-divider />
        <v-list-item-group v-model="language">
          <v-list-item
            v-for="language in languages"
            :key="`notification-key-${language.id}`"
            @click="setCurrentLanguage(language.icon)"
          >
            <v-list-item-icon>
              <div style="font-size: 20px">
                <v-img
                  :max-height="iconSize"
                  :max-width="iconSize"
                  :src="flagIconUrl(language.icon)"
                />
              </div>
            </v-list-item-icon>
            <v-list-item-title>{{ language.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import italyFlag from "../../assets/icons/italy-flag.svg";
import franceFlag from "../../assets/icons/france-flag.svg";
import englandFlag from "../../assets/icons/england-flag.svg";
import spainFlag from "../../assets/icons/spain-flag.svg";
import germanFlag from "../../assets/icons/german-flag.svg";

export default {
  data: () => {
    return {
      iconMap: new Map(),
      iconSize: 24,
      languages: [
        {
          id: 1,
          icon: "it",
          title: "Italiano",
        },
        {
          id: 2,
          icon: "gb",
          title: "English",
        },
        {
          id: 3,
          icon: "de",
          title: "Deutsch",
        },
        {
          id: 4,
          icon: "fr",
          title: "Français",
        },
        {
          id: 5,
          icon: "es",
          title: "Español",
        },
      ],
      language: "",
    };
  },
  created() {
    this.iconMap.set("it", italyFlag);
    this.iconMap.set("fr", franceFlag);
    this.iconMap.set("es", spainFlag);
    this.iconMap.set("de", germanFlag);
    this.iconMap.set("gb", englandFlag);
  },
  computed: {
    flagIconUrl() {
      return (icon) => {
        return this.iconMap.get(icon);
      };
    },
    cardColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      return this.$vuetify.theme.themes[theme].cardColor;
    },
  },
  methods: {
    setCurrentLanguage(language) {
      const currentPath = this.$router.history.current.path;
      const route = currentPath.split("/")[2];

      switch (language) {
        case "gb":
          this.$i18n.locale = "en";
          break;
        case "it":
          this.$i18n.locale = "it";
          break;
        case "es":
          this.$i18n.locale = "es";
          break;
        case "fr":
          this.$i18n.locale = "fr";
          break;
        case "de":
          this.$i18n.locale = "de";
          break;
      }
      const locale = this.$i18n.locale;
      const routeName = this.getRouteName(route);

      if (this.$route.params.locale !== locale) {
        this.$router.push({ name: routeName, params: { locale: locale } });
      }
    },
    getRouteName(route) {
      switch (route) {
        case "home":
          return "Home";
        case "info":
          return "Info";
        case "where-we-are":
          return "WhereWeAre";
        case "contacts":
          return "Contacts";
        case "gallery":
          return "Gallery";
      }
    },
  },
};
</script>
