import { storage, analytics, logEvent } from '../config/firebase';
import { ref, getDownloadURL, listAll } from "firebase/storage";

const PAGE_TOKEN = 9;

async function getUrl(imagePath) {
    const storageRef = ref(storage, imagePath);

    try {
        return (await getDownloadURL(storageRef));
    } catch (error) {
        logEvent(analytics, 'exception', { error: error.code });
    }
}

async function getUrls() {
    let urls = [];

    try {
        if (localStorage.getItem('urls') !== null) {
            urls = JSON.parse(localStorage.getItem('urls'));
            urls = urls.split(',');
        } else {
            urls.push(await getUrl("image/carousel/bus.jpg"));
            urls.push(await getUrl("image/carousel/cows.jpeg"));
            urls.push(await getUrl("image/carousel/landing_field.png"));
            urls.push(await getUrl("image/carousel/windsock.png"));
            localStorage.setItem('urls', JSON.stringify(urls.toString()));
        }
    } catch (error) {
        console.error(error.message);
    }
    return urls;
}

async function listAllFiles(pageNumber) {
    const listRef = ref(storage, "image/gallery");
    let images = [];

    try {
        const listResult = await listAll(listRef);

        if (localStorage.getItem('images') !== null) {
            images = JSON.parse(localStorage.getItem('images'));
            images = images.split(',');
        } else {
            for (let itemRef of listResult.items) {
                const url = await getDownloadURL(itemRef);
                images.push(url);
            }
            localStorage.setItem('images', JSON.stringify(images.toString()));
        }
    } catch (error) {
        console.error(error.message);
    }

    switch (pageNumber) {
        case 1:
            return images.slice(0, PAGE_TOKEN);
        case 2:
            return images.slice(10, PAGE_TOKEN * 2);
        case 3:
            return images.slice(19, PAGE_TOKEN * 3);
    }
    return images;
}

export { getUrl, getUrls, listAllFiles };