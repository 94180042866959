import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.lime.base,
                secondary: colors.red.lighten4,
                accent: colors.indigo.base,
                error: colors.red,
                background: colors.blueGrey.lighten4,
                cardColor: colors.shades.white,
                sheet: colors.shades.white,
                iconColor: colors.blueGrey.darken3,
                limeCardColor: colors.lime.base,
                carbonColor: '#232323',
                toolbarColor: colors.blue.darken2
            },
            dark: {
                primary: colors.lime.base,
                secondary: colors.red.lighten4,
                accent: colors.indigo.base,
                error: colors.red,
                background: colors.blueGrey.darken4,
                cardColor: colors.blueGrey.darken3,
                sheet: colors.blueGrey.darken3,
                iconColor: colors.shades.white,
                limeCardColor: colors.lime.base,
                carbonColor: '#232323',
                toolbarColor: colors.blue.darken2
            }
        },
    },
    icons: {
        iconfont: 'fa',
    },
});
