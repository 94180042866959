<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Toolbar v-if="showComponents" />
    <v-main class="pt-0 pt-md-0 pb-0 pr-0 pl-0">
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
      <BorderWaves v-if="showComponents" />
    </v-main>
    <Footer v-if="showComponents" />
  </v-app>
</template>

<script>
import Toolbar from "@/components/Utils/Toolbar";
import Footer from "@/components/Utils/Footer";
import BorderWaves from "@/components/Utils/BorderWaves";

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Vola in biposto con noi! Battesimi dell'aria, anniversari, compleanni e voli di gruppo... regala o regalati un volo in tandem con il deltaplano, accompagnato da un pilota esperto e professionale.",
      },
      {
        name: "keywords",
        content:
          "deltaplano, Laveno, laveno, scuola deltaplano, biposto, hanggliding, hang-gliding school, tandem, volo libero, fly, flugen",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "google-site-verification", content: "rzCAlJo1xOWbwympp9RMkm9_SrSvdoccurT2ST5Oza4" }
    ],
  },
  components: {
    Toolbar,
    Footer,
    BorderWaves,
  },
  computed: {
    showComponents() {
      return this.$route.name !== "Index";
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style scoped>
.v-main {
  background-image: url("~@/assets/images/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>