<template>
  <v-col cols="12" sm="10" md="6" offset="0" offset-sm="1" offset-md="0">
    <div :data-aos="flip" data-aos-duration="1000">
      <v-hover v-slot="{ hover }" open-delay="100">
        <v-card
          :color="cardColor"
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover mx-auto': hover }"
        >
          <v-img :src="src" :height="height" :alt="alt" class="grey darken-4">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-hover>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    cardColor: { type: String },
    src: { type: String },
    alt: { type: String },
    height: { type: String },
    flip: { type: String },
  },
};
</script>
