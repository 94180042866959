<template>
  <v-row>
    <v-col md="6" offset-md="3">
      <div data-aos="zoom-in-up" data-aos-duration="1000">
        <h1
          class="
            text-center
            text-decoration-underline
            text-uppercase
            text-md-h4
            text-md-h4
            text-h5
          "
        >
          {{ title }}
        </h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
};
</script>
